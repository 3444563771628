import React from "react"

import Layout from "components/layout"
import SEO from "components/seo"

import Cursos from "components/seccions/Cursos"
import Extraescolars from "components/seccions/Extraescolars"
import Tallers from "components/seccions/Tallers"
import Contacte from "components/seccions/Contacte"

const IndexPage = () => (
  <Layout>
    <SEO title="Fem teatre a l'escola" />
    <div>
      <div className="mx-auto max-w-5xl h-64 px-4 my-16 pt-4">
        <h1 className="mb-1 font-bold text-6xl leading-none">gaudir teatre</h1>
        <p className="text-3xl leading-tight mt-4 sm:mt-10 ">Fem teatre a l'escola</p>
      </div>

      <Cursos />

      <Extraescolars />

      <Tallers />

      <Contacte />

    </div>
  </Layout>
)

export default IndexPage
