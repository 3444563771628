import React from 'react'

const Contacte = () => {
  return (
    <div id="contacte" className="mx-auto max-w-screen-xl text-center py-16">
      <h3 className="mb-10 text-4xl sm:text-5xl">Contacte</h3>
      <div className="font-medium">
        <div className="mb-6 font-medium text-xl">
          <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-8 w-8 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
          </svg>
          Xuspi Hernández
        </div>
        <div className="mb-6 font-medium text-xl">
          <a href="tel:+34679794567">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-8 w-8 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
            </svg>
            679794567
          </a>
        </div>
        <div className="mb-6 font-medium text-xl">
          <a href="mailto:info@gaudirteatre.com">
            <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-8 w-8 ml-8 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
            </svg>
            info@gaudirteatre.com
          </a>
        </div>
      </div>
    </div>
  )
}

export default Contacte
