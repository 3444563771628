import React from "react"
import { Link } from "gatsby"

const cursos = [
  {
    date: "Dissabte, 20 de novembre",
    title: "El joc del clown",
    author: "Claret Papiol",
    desc: "",
    profile: "/cursos/clown-claret.png",
  },
  {
    date: "Dissabte, 27 de novembre",
    title: "El nostre clown de cada dia",
    author: "Pere Hosta",
    desc: "",
    profile: "/cursos/clown-pere.png",
  },
  {
    date: "Dissabte, 4 de desembre",
    title: "Silenci, neix un clown",
    author: "Ovidi Llorente",
    desc: "",
    profile: "/cursos/clown-ovidi.png",
  },
]

const Cursos = () => {
  return (
    <div id="cursos" className="bg-red-700 text-red-100 py-12 sm:py-16 px-4 ">
      <div className="mx-auto max-w-screen-xl text-center">
        <h3 className="mb-2 text-4xl sm:text-5xl">
          1, 2, 3 Clown! <span className="text-xl">2ª edició</span>
        </h3>
        <div className="sm:flex justify-center mb-4 text-red-300 text-normal text-center">
          <div className="mt-2">
            🗓 Dissabtes 20, 27 de novembre i 4 de desembre
          </div>
          <div className="mt-2 pl-4">🕚 10:00h - 14:00h</div>
        </div>
        <div className="mx-auto max-w-screen-lg text-center mb-6 font-lighter">
          3 dissabtes, 3 pallassos. Curs intensiu de clown on podràs descobrir i
          aprofundir l’increïble món del pallasso.
        </div>
        <div className="mx-auto max-w-screen-lg text-center sm:flex pt-4">
          {cursos.map(({ title, date, author, profile }) => (
            <div className="mx-2 mb-4 px-2 py-6 bg-white text-black flex-1 rounded flex flex-col justify-between">
              <Link to="/123-clown" className="hover:underline text-red-700">
                <h5 className="text-2xl font-medium uppercase pt-2 pb-4 text-red-700">
                  {title}
                </h5>
              </Link>
              <div className="flex flex-1 flex-grow justify-center content-end">
                <Link
                  to="/123-clown"
                  className="hover:text-gray-300 flex flex-col justify-end content-center"
                >
                  <img
                    className="w-64 h-64 rounded-full"
                    src={profile}
                    alt={author}
                  />
                </Link>
              </div>
              <div>
                <div className="text-xl font-medium uppercase text-gray-700 mt-4">
                  🤡 {author}
                </div>
                <div className="text-base font-normal text-gray-700 mt-1">
                  🗓 {date}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="mx-auto max-w-screen-lg pt-6">
          <div class="lg:mx-auto lg:w-1/3 text-center border-4 rounded border-red-100 hover:border-red-300 mx-2">
            <Link
              to="/123-clown"
              className="flex justify-between text-red-100 hover:text-red-300 block px-8 lg:px-12 py-4 uppercase font-medium text-lg"
            >
              <div>Info i Inscripcions</div>
              <div class="text-xl"> → </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Cursos
