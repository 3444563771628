import React from 'react'

const Tallers = () => {
  return (
    <div id="tallers" className="bg-red-700 text-red-100 py-8 sm:py-16 mx-auto">
      <div className="mx-auto max-w-screen-xl text-center">
        <h3 className="mb-10 text-4xl sm:text-5xl">Tallers de Teatre</h3>
        <div className="mx-auto max-w-screen-lg text-center sm:flex">
          <div className="mx-2 mb-4 px-8 py-8 bg-white text-black flex-1 rounded">
            <h5 className="text-2xl font-medium uppercase">Infantil</h5>
            <hr className="mt-4 mb-6 border border-red-600" />
            <div className="text-left sm:text-justify">
              <p>Destinat a cursos d'educació infantil.</p>
              <p>Activitats, jocs, pintura de cares i molta imaginació.</p>
              <p>En aquest taller jugarem, aprendrem i crearem personatges per poder fer una historia entre tots.</p>
              <p><b className="font-semibold">Seguint aquesta metodologia es poden fer tallers de teatre a mida, treballant temes
                concrets que estiguin treballant a l'escola.</b></p>
            </div>
          </div>
          <div className="mx-2 mb-4 px-8 py-8 bg-white text-black flex-1 rounded">
            <h5 className="text-2xl font-medium uppercase">Primària i secundària</h5>
            <hr className="mt-4 mb-6 border border-red-600" />
            <div className="text-left sm:text-justify">
              <p>Destinat a cursos d'educació primària i secundària.</p>
              <ul>
                <li className="mb-4"><span role="img" aria-label="heroi">🦹🏻‍♀️</span> <b>Creació de personatges</b>: Estar boig i fer-se el boig són dues coses molt diferents. En aquest taller farem jocs teatrals per trobar la part més esbojarrada que tenim a dins.</li>
                <li className="mb-4"><span role="img" aria-label="clown">🤡</span> <b>Clown</b>: Fer el pallasso no és una cosa fàcil. En aquest taller farem jocs teatrals per treure a fora la part més còmica nostre i del grup.</li>
                <li className="mb-4"><span role="img" aria-label="mim">🤫</span> <b>Mim</b>: No cal parlar per dir moltes coses. En aquest taller farem jocs de mim i aprendrem tècniques d'allò més divertides.</li>
                <li className="mb-4"><span role="img" aria-label="circ">🎪</span> <b>Circ</b>: La vida dona moltes tombarelles, però els artistes de circ més. En aquest taller farem jocs de circ i aprendrem moltes coses d'aquest món tant màgic.</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Tallers
