import React from 'react'
import { Link } from 'gatsby'

const items = [
  {
    icon: <>
      <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10 mr-4 inline-block hover:hidden" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M14.828 14.828a4 4 0 01-5.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
      </svg>
    </>,
    content: <>
      Expressió corporal
      </>,
    path: '/extraescolars/#expressio-corporal'
  }, {
    icon: <>
      <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 3v4M3 5h4M6 17v4m-2-2h4m5-16l2.286 6.857L21 12l-5.714 2.143L13 21l-2.286-6.857L5 12l5.714-2.143L13 3z" />
      </svg>
    </>,
    content: <>
      Teatre a l'escola
    </>,
    path: '/extraescolars/#teatre-escola'
  }, {
    icon: <>
      <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10 mr-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253" />
      </svg>
    </>,
    content: <>
      Contes a l'escola
    </>,
    path: '/extraescolars/#contes-escola'
  }
]

const Extraescolars = () => {
  return (
    <div id="extraescolars" className="mx-auto max-w-screen-xl text-center py-16">
      <h3 className="mb-10 text-4xl sm:text-5xl">Extraescolars</h3>
      {
        items.map( ({ icon, content, path }, index) => (
          <Link key={index} to={path} className="text-red-100 hover:underline hover:text-gray-300">
            <div className="flex justify-between align-center bg-red-700 rounded py-6 px-6 pr-2 sm:px-12 my-6 mx-2 sm:mx-20 text-left text-red-100 leading-8">
              <div className="flex align-center">
                {icon}
                <h5 className="flex align-center text-2xl sm:text-3xl m-0 font-medium leading-10 ">
                  {content}
                </h5>
              </div>
                <svg xmlns="http://www.w3.org/2000/svg" className="inline-block h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
                </svg>
            </div>
          </Link>
        ))
      }
    </div>
  )
}

export default Extraescolars
